<script>
  import { screens } from '@/../tokens'
  import placeholderImage from '@/images/placeholders/placeholder.gif'

  const defaultLazyLoadConfig = {
    // The default selector is 'img', but we set it here to be explicit.
    selector: 'img',
    loading: placeholderImage,
  }

  /**
   * A generic image component.
   */
  export default {
    props: {
      /** The image’s source URL. */
      src: {
        type: String,
        default: undefined,
      },
      /** The image’s alternative text. */
      alt: {
        type: String,
        required: true,
      },
      /** The image’s aspect ratio (width / height). When set to a different aspect ratio than the image’s, it will be cropped (`object-fit: cover`). */
      aspectRatio: {
        type: Number,
        default: undefined,
      },
      /** Whether optimized responsive variants of this image should be generated. Only works if `src` is an absolute URL. Should you wish to customize the quantity and size of image variants, this prop also accepts an array of widths (e.g. `[100, 200, 300]`) or one of the size keywords (`xs`, `sm`, `md`, `lg`, or `xl`), which are presets for different arrays of widths. The boolean value of `true` is identical to `xl`. */
      responsive: {
        type: [Boolean, Array, String],
        default: false,
        validator: (value) =>
          typeof value !== 'string' || ['xs', 'sm', 'md', 'lg', 'xl'].includes(value),
      },
      /** Only if `responsive` is used. The `sizes` HTML attribute. Can either be a string or an object where each key is a Tailwind screen (e.g. `sm`) and each value is the width the image is estimated to be at that screen. For example: `{ default: '100vw', sm: '50vw', lg: '25vw' }`. The `default` key is optional; if omitted, the image is assumed to be `100vw` below the smallest specified screen. */
      sizes: {
        type: [String, Object],
        default: '100vw',
      },
      /** Whether the image should be lazy-loaded. Can either be a boolean or an object with `vue-lazyload` configuration properties. See https://github.com/hilongjw/vue-lazyload#constructor-options */
      lazyLoad: {
        type: [Boolean, Object],
        default: false,
      },
      /** Whether the image has a 100% border-radius */
      rounded: {
        type: Boolean,
        default: false,
      },
      /** Whether the image has a transparent background */
      backgroundTransparent: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        loadingError: false,
      }
    },
    computed: {
      finalAspectRatio() {
        // If there is no `src` and no `aspectRatio`, force an aspect ratio of 1:1, otherwise the height will be 0
        if (!this.src && !this.aspectRatio) {
          return 1
        }
        return this.aspectRatio
      },
      variantWidths() {
        if (!this.responsive || this.loadingError) {
          return undefined
        }
        if (Array.isArray(this.responsive)) {
          return this.responsive
        }
        const preset = typeof this.responsive === 'string' ? this.responsive : 'xl'
        // These preset variant widths are arbitrarily similar to the Tailwind screens
        switch (preset) {
          case 'xs':
            return [128, 256]
          case 'sm':
            return [128, 256, 384, 512]
          case 'md':
            return [128, 256, 384, 512, 640, 768]
          case 'lg':
            return [128, 256, 384, 512, 640, 768, 1024, 1280]
          case 'xl':
            return [128, 256, 384, 512, 640, 768, 1024, 1280, 1536, 2048]
          default:
            return undefined
        }
      },
      variants() {
        if (!this.src) {
          return []
        }
        if (!this.variantWidths || this.variantWidths.length === 0) {
          return [{ src: this.src }]
        }
        return this.variantWidths.map((variantWidth) => {
          const resizeOptions = {
            width: variantWidth,
            quality: 85,
            format: 'auto',
          }
          if (this.finalAspectRatio) {
            resizeOptions.height = variantWidth / this.finalAspectRatio
            resizeOptions.fit = 'cover'
          }
          return {
            src: `https://shop.alltrue.com/cdn-cgi/image/${encodeURIComponent(
              Object.entries(resizeOptions)
                .map(([optionName, optionValue]) => `${optionName}=${optionValue}`)
                .join(',')
            )}/${encodeURIComponent(this.src)}`,
            width: variantWidth,
          }
        })
      },
      finalSrc() {
        return this.variants[0]?.src
      },
      srcset() {
        if (this.variants.length <= 1) {
          return undefined
        }
        return this.variants.map((variant) => `${variant.src} ${variant.width}w`).join(', ')
      },
      srcAttributes() {
        if (!this.finalSrc) {
          return undefined
        }
        if (this.lazyLoad) {
          return {
            'data-src': this.finalSrc,
            'data-srcset': this.srcset,
          }
        }
        return {
          src: this.finalSrc,
          srcset: this.srcset,
        }
      },
      finalSizes() {
        if (!this.srcset) {
          return undefined
        }
        if (typeof this.sizes === 'string') {
          return this.sizes
        }
        const sizes = {
          default: '100vw',
          ...this.sizes,
        }
        const sizesString = Object.entries(screens)
          .reverse()
          .reduce((sizesString, [screenKey, screenWidth]) => {
            if (sizes[screenKey]) {
              const thisSizeString = `(min-width: ${screenWidth}) ${sizes[screenKey]}`
              if (sizesString) {
                return `${sizesString}, ${thisSizeString}`
              }
              return thisSizeString
            }
            return sizesString
          }, '')
        if (sizesString) {
          return `${sizesString}, ${sizes.default}`
        }
        return sizes.default
      },
      lazyLoadConfig() {
        if (this.lazyLoad) {
          if (typeof this.lazyLoad === 'object') {
            return {
              ...defaultLazyLoadConfig,
              ...this.lazyLoad,
            }
          }
          return defaultLazyLoadConfig
        }
        // Override the default selector ('img') so that <img /> tags aren't added to the `lazy` collection by `vue-lazyload`.
        return { selector: undefined }
      },
    },
  }
</script>

<template>
  <div
    v-lazy-container="lazyLoadConfig"
    :class="`${rounded ? 'rounded-full' : ''} ${backgroundTransparent ? '' : 'bg-gray-hover'}`"
  >
    <div v-if="finalAspectRatio" :style="{ paddingBottom: `${(1 / finalAspectRatio) * 100}%` }" />
    <img
      v-if="srcAttributes"
      v-bind="srcAttributes"
      :sizes="finalSizes"
      :alt="alt"
      :class="`${finalAspectRatio ? 'absolute left-0 top-0 w-full h-full object-cover' : 'w-full'}
      ${rounded ? 'rounded-full' : ''}`"
      @error="loadingError = true"
    />
  </div>
</template>
